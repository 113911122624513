import React, { useState, useEffect } from "react";
import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { fetchFamilyData } from "./firestoreFunctions";
import { FaWhatsappSquare } from "react-icons/fa";

// ZMap Component
function ZMap() {
  // State to store family data, markers, modal state, selected family, and selected city
  const [family, setFamily] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [hideMap, setHideMap] = useState(false);
  const [selectedCity, setSelectedCity] = useState(""); // Added state for the selected city
  const [position, setPosition] = useState({
    lat: -33.80743570062853,
    lng: 150.98040528879093,
  });

  // Fetch family data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFamilyData();
      setFamily(data);
    };

    fetchData();
  }, []);
  // Update markers when family data changes or when the selected city changes
  useEffect(() => {
    const fetchMarkers = () => {
      // Map family data to markers
      const markerPromises = family.map((person) => {
        const coordinates = person.location; // Use the family location directly

        if (coordinates && (!selectedCity || person.city === selectedCity)) {
          // Determine the Pin color based on the selected family
          const pinColor =
            selectedFamily && selectedFamily.fullName === person.fullName
              ? "red" // Change to the desired color
              : "blue"; // Default color

          return (
            <AdvancedMarker
              onClick={() => {
                setOpen(true);
                setSelectedFamily(person);
                setPosition({
                  lat: coordinates._lat,
                  lng: coordinates._long,
                });
              }}
              key={person.fullName}
              position={{ lat: coordinates._lat, lng: coordinates._long }}>
              <Pin
                background={pinColor}
                borderColor="black"
                glyphColor="white"
              />
            </AdvancedMarker>
          );
        }

        return null;
      });

      // Update the markers state with valid markers
      setMarkers(markerPromises.filter(Boolean));
    };

    fetchMarkers();
  }, [family, selectedFamily, selectedCity]);

  // Get unique cities from family data
  const cities = [...new Set(family.map((person) => person.city))];

  // Render the ZMap component
  return (
    <div className="mapOC">
      <div style={{ height: "90vh", width: "90vw", marginBottom: "16px" }}>
        {/* Google Maps APIProvider with API key */}
        <APIProvider apiKey={"AIzaSyBf7MiKr9-0ke_XygSlwl-P3fCiXg3QmBs"}>
          {/* Google Maps Map component with initial zoom, center, and mapId */}
          <Map zoom={15} center={position} mapId={"ea08e9c568416d41"}>
            {/* Render markers on the map */}
            {markers}
          </Map>
        </APIProvider>
      </div>

      {/* Family list */}
      <div
        className="families"
        style={{ maxHeight: 570, overflow: "auto", width: "90vw" }}>
        <div className="filter">
          {/* Dropdown for selecting the city */}
          <select onChange={(e) => setSelectedCity(e.target.value)}>
            <option value="">All Cities</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <ul>
          {family
            .filter((person) =>
              selectedCity ? person.city === selectedCity : true
            )
            .map((person) => (
              <div key={person.fullName}>
                <li
                  className="eachFamily"
                  key={person.fullName}
                  onClick={() => {
                    setOpen(true);
                    setSelectedFamily(person);
                    setPosition({
                      lat: person.location._lat,
                      lng: person.location._long,
                    });
                  }}
                  style={{
                    color:
                      selectedFamily &&
                      selectedFamily.fullName === person.fullName
                        ? "#669bbc"
                        : "#003049",
                    fontWeight:
                      selectedFamily &&
                      selectedFamily.fullName === person.fullName
                        ? "bolder"
                        : "normal",
                    backgroundColor:
                      selectedFamily &&
                      selectedFamily.fullName === person.fullName
                        ? "#003049"
                        : "#669bbc",
                  }}>
                  HOF Full Name: {person.fullName}
                  <br />
                  Phone Number: {person.phoneNumber}
                  <a
                    href={`https://wa.me/${person.phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {" "}
                    &nbsp;
                    <FaWhatsappSquare color="#003049" size={17} />
                  </a>
                  <br />
                  Address: {person.Address}
                  <br />
                  City: {person.city}
                </li>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default ZMap;
