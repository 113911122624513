// ... (other imports)
import React, { useState } from "react";
import auth from "../firebase";
import {
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TOTPModal = ({ res }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const navigate = useNavigate();
  const handleTOTPSubmit = async () => {
    const resolver = getMultiFactorResolver(auth, res);
    try {
      // Ask the user which second factor to use.
      if (resolver.hints[0].factorId === TotpMultiFactorGenerator.FACTOR_ID) {
        // Ask the user for the OTP code from the TOTP app.
        const multiFactorAssertion =
          TotpMultiFactorGenerator.assertionForSignIn(
            resolver.hints[0].uid,
            verificationCode
          );

        // Finalize the sign-in.
        return resolver
          .resolveSignIn(multiFactorAssertion)
          .then(function (userCredential) {
            // The user successfully signed in with the TOTP second factor.
            toast("Authenticated!");
            navigate("/home");
          });
      }
    } catch (error) {
      toast(error.message);
    }
  };
  return (
    <div className="totp-modal" autoFocus>
      <h2>Enter OTP Code</h2>
      <label>Verification Code:</label>
      <input
        type="text"
        autoFocus
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <button onClick={handleTOTPSubmit}>Submit OTP</button>
    </div>
  );
};

export default TOTPModal;
