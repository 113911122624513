import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../App.css";
import SignUpPage from "./SignUpPage";
import WelcomePage from "./WelcomePage";
import LoginPage from "./Login";
import Dashboard from "./Dashboard";
import PrivateRoute from "./PrivateRoute";
import AddMarkerForm from "./AddMarkerForm";
import EnrollTOTP from "./EnrollTOTP";
import TOTPModal from "./OTPModal";
import Home from "./Home";
import ForgotPassword from "./ForgotPassword";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="body">
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/home"
              element={<PrivateRoute children={<Home />} />}
            />
            <Route
              path="/dashboard"
              element={<PrivateRoute children={<Dashboard />} />}
            />
            <Route
              path="/addMarker"
              element={<PrivateRoute children={<AddMarkerForm />} />}
            />
            <Route
              path="/enrollotp"
              element={<PrivateRoute children={<EnrollTOTP />} />}
            />
            <Route
              path="/verifyMFA"
              element={<PrivateRoute children={<TOTPModal />} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
