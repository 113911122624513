import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/authProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const { forgotPassword } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await forgotPassword(email);
      toast(
        "If your account exists, you may have received an email. Please check your inbox."
      );
      // You can navigate the user to a different page after successful submission
      // navigate("/some-page");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      toast("Error sending password reset email. Please try again.");
    }
  };

  return (
    <div className="centered-container">
      <div className="userForm">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit">Send Verification Email</button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
