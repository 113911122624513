import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  GeoPoint,
} from "firebase/firestore";
import GoogleGeocodingService from "@qc/google-geocoding-service";
import { db } from "../firebase";

const geocodingService = new GoogleGeocodingService({
  apiKey: "AIzaSyBf7MiKr9-0ke_XygSlwl-P3fCiXg3QmBs",
});

export async function fetchAndProcessRecords() {
  try {
    // Fetch records where geo-coordinates are set to 0
    const recordsQuery = query(
      "family",
      where("location", "=<", { lat: 0, lng: 0 })
    );
    const querySnapshot = await getDocs(recordsQuery);

    // Usage

    // Process each record
    querySnapshot.forEach(async (doc) => {
      const data = doc.data();

      // Use the geocoding function to get new coordinates and city
      const { coordinates, city } = await getLocationFromAddress(data.Address);

      if (coordinates && city) {
        // Update the record in Firestore with new GeoPoint and City
        const recordRef = doc(db, "family", doc.id);
        await updateDoc(recordRef, {
          location: new GeoPoint(coordinates.lat, coordinates.lng),
          city: city,
        });

        console.log(`Record updated for ${data.fullName}`);
      } else {
        console.error(`Unable to get coordinates or city for ${data.fullName}`);
      }
    });

    console.log("Processing complete.");
  } catch (error) {
    console.error("Error fetching and processing records:", error);
  }
}

async function getLocationFromAddress(address) {
  try {
    const results = await geocodingService.geocode(address);
    const cityComponent = results?.[0]?.address_components.find((component) =>
      component.types.includes("locality")
    );
    const city = cityComponent ? cityComponent.long_name : null;
    const geometry = results?.[0]?.geometry?.location;
    console.log("Using this function");
    return geometry && city
      ? { coordinates: { lat: geometry.lat, lng: geometry.lng }, city }
      : {};
  } catch (error) {
    console.error("Error geocoding address:", error);
    return {};
  }
}
