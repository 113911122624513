import React, { useEffect, useState } from "react";
import { GeoPoint } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import GoogleGeocodingService from "@qc/google-geocoding-service";
import { AddFamily } from "./firestoreFunctions";

const geocodingService = new GoogleGeocodingService({
  apiKey: "AIzaSyBf7MiKr9-0ke_XygSlwl-P3fCiXg3QmBs",
});

function AddMarkerForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    Address: "",
  });

  async function handleOnSubmit(e) {
    e.preventDefault();
    try {
      // Get coordinates and city from the address
      const { coordinates, city } = await getLocationFromAddress(
        formData.Address
      );

      if (coordinates && city) {
        // Save the family data with GeoPoint and City to Firestore
        AddFamily(
          formData.fullName,
          formData.Address,
          formData.phoneNumber,
          new GeoPoint(coordinates.lat, coordinates.lng),
          city
        );

        navigate("/dashboard");
      } else {
        console.error("Unable to get coordinates or city from the address");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  useEffect(() => {
    getLocationFromAddress("Unit 304, 91b Bridge Road, Westmead, 2145, NSW");
  }, []);

  // Function to get location coordinates and city from an address using the Geocoding Service
  async function getLocationFromAddress(address) {
    try {
      const results = await geocodingService.geocode(address);
      // Find the 'locality' type in address_components
      const cityComponent = results?.[0]?.address_components.find((component) =>
        component.types.includes("locality")
      );
      // If 'locality' type is found, use its long_name as the city
      const city = cityComponent ? cityComponent.long_name : null;
      const geometry = results?.[0]?.geometry?.location;
      return geometry && city
        ? { coordinates: { lat: geometry.lat, lng: geometry.lng }, city }
        : {};
    } catch (error) {
      console.error("Error geocoding address:", error);
      return {};
    }
  }

  return (
    <div className="centered-container">
      <div className="userForm">
        {" "}
        <form onSubmit={handleOnSubmit}>
          <h3>Add Family</h3>
          <label>Full Name</label>
          <input
            type="text"
            name="fullName"
            required
            placeholder="Full Name"
            value={formData.fullName}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
          />
          <br />
          <label>Phone Number</label>
          <input
            type="number"
            name="phoneNumber"
            placeholder="Phone Number"
            required
            value={formData.phoneNumber}
            onChange={(e) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
          />
          <br />
          <label>Address</label>
          <input
            type="text"
            name="Address"
            placeholder="Please enter full address with State and Postcode"
            required
            value={formData.Address}
            onChange={(e) =>
              setFormData({ ...formData, Address: e.target.value })
            }
          />

          <button type="submit">Add Family</button>
          <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        </form>
      </div>
    </div>
  );
}

export default AddMarkerForm;
